<template>
  <SettingsLayout>
    <div class="settings-content">
      <div class="heading">
        <h1 class="h2">Plans</h1>
      </div>
      <div class="block">
        <div class="mxw">
          <div class="watermark-toggle" v-if="showYearlyToggle">
            <label class="checkbox-wrap">
              <input type="checkbox" id="toggle" v-model="isYearly" @change="handleYearlyToggle" />
              <span class="label" style="padding-right: 20px;">Monthly Billing</span>
              <span class="toggle">
                <span class="circle"></span>
              </span>
              <span class="label">Yearly Billing</span>
              <a href="#" class="badge">Save 50%</a>
            </label>
          </div>
        </div>
      </div>
      <div class="plans-holder">
        <!-- Free Plan -->
        <div class="plan-single">
          <div class="head">
            <h2 class="h3">Free</h2>
          </div>
          <div class="price"><span class="unit">$</span> <span class="amount">0</span></div>
          <a v-if="subscription" @click.prevent="navigateToBillingPortal" href="#" class="plan-btn">Downgrade Plan</a>
          <a v-else href="#" class="plan-btn"><i class="icon-check"></i>Current Plan</a>
          <strong class="title">Key Features</strong>
          <ul class="features-list">
            <li>3 free files per day</li>
            <li>AI Summaries</li>
            <li>Powerful AI chat feature</li>
            <li>Search</li>
            <li>Support for audio and video content</li>
            <li>Export in multiple formats (TXT, SRT, VTT)</li>
          </ul>
          <br />
          <ul class="features-list negative-features">
            <li>Transcripts capped to 25 mins</li>
            <li>Lower processing priority</li>
          </ul>
        </div>

        <!-- Standard Plan -->
        <div class="plan-single most-popular">
          <div class="head">
            <h2 class="h3">Standard</h2>
            <a v-if="isYearly" href="#" class="badge">Save $120</a>
          </div>
          <div class="price">
            <span class="unit">$</span>
            <span class="amount">{{ displayPrice.amount }}</span>
            <span style="padding-left: 5px;">Per month</span>
            <span v-if="isYearly" class="note">{{ displayPrice.note }}</span>
          </div>
          <a @click.prevent="handleUpgrade" class="plan-btn btn-upgrade"
            :class="{ 'current-plan': isCurrentPlan(isYearly ? 'standard_yearly_v1' : 'standard_monthly_v1') }">
            {{ upgradeButtonText }}
          </a>
          <strong class="title">Key Features</strong>
          <ul class="features-list">
            <li>Unlimited number of transcripts</li>
            <li>Up to 10-hour transcripts per file</li>
            <li>Highest Processing Priority</li>
            <li>Export in multiple formats (TXT, PDF, SRT)</li>
            <li>Full-index search</li>
            <li>AI Summaries</li>
            <li>Powerful AI chat feature</li>
            <li>Speaker Identification</li>
          </ul>
        </div>

        <!-- Enterprise Plan -->
        <div class="plan-single">
          <div class="head">
            <h2 class="h3">Enterprise</h2>
          </div>
          <div class="price"><span class="unit"></span> <span class="amount">-</span></div>
          <a href="mailto:support@audioscribe.io" class="plan-btn">Contact Us</a>
          <strong class="title">Key Features</strong>
          <ul class="features-list">
            <li>Everything in the Standard Plan</li>
            <li>Running in your own private cloud environment</li>
          </ul>
        </div>
      </div>
    </div>
  </SettingsLayout>
</template>

<script>
import SettingsLayout from "@/components/SettingsLayout.vue";
import { createCheckoutSession, createCustomerPortalSession } from "@/services/workspace.service.js";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { mapStores } from "pinia";

export default {
  name: "SettingsPlansView",
  components: {
    SettingsLayout,
  },
  data() {
    return {
      isYearly: false, // Track if yearly billing is enabled
      subscription: null, // Hold the subscription object
    };
  },
  computed: {
    ...mapStores(useWorkSpaceStore),
    displayPrice() {
      if (this.isYearly) {
        return {
          amount: '10.00',
          note: 'Billed Annually as $120'
        };
      }
      return { amount: '19.99', note: '' };
    },
    showYearlyToggle() {
      return !this.subscription || (this.subscription && this.subscription.billingCycle === 'MONTH');
    },
    upgradeButtonText() {
      if (this.subscription && this.subscription?.status === "ACTIVE") {
        if (this.isCurrentPlan(this.isYearly ? 'standard_yearly_v1' : 'standard_monthly_v1')) {
          return 'Current Plan';
        } else if (this.isYearly && this.subscription.currentPlanName === 'standard_monthly_v1') {
          return 'Upgrade Plan';
        } else {
          return 'Upgrade';
        }
      }
      return 'Upgrade Plan';
    }
  },
  methods: {
    async handleUpgrade() {
      if (this.subscription?.status === "ACTIVE") {
        // If user is subscribed, navigate to Billing Portal
        this.navigateToBillingPortal();
      } else {
        // If user is not subscribed, proceed with checkout
        try {
          const billingPeriod = this.isYearly ? 'yearly' : 'monthly';
          const { data } = await createCheckoutSession(billingPeriod);
          if (data.stripeCheckoutSessionUrl) {
            window.location.href = data.stripeCheckoutSessionUrl; // Navigate to the Stripe checkout page
          }
        } catch (error) {
          console.error("Error during checkout session creation", error);
        }
      }
    },
    async fetchSubscriptionStatus() {
      try {
        await this.workspaceStore.fetchSubscriptionStatus();
        this.subscription = this.workspaceStore.isSubscribedState ? { status: "ACTIVE" } : null;
        this.isYearly = this.subscription && this.subscription.billingCycle === 'YEAR';
      } catch (error) {
        console.error("Error fetching subscription status", error);
      }
    },
    async navigateToBillingPortal() {
      try {
        const response = await createCustomerPortalSession();
        if (response.data.customerPortalUrl) {
          window.location.href = response.data.customerPortalUrl; // Navigate to the billing portal
        }
      } catch (error) {
        console.error("Failed to navigate to billing portal", error);
      }
    },
    handleYearlyToggle() {
      if (this.isYearly && this.subscription && this.subscription.currentPlanName === 'standard_monthly_v1') {
        this.$forceUpdate(); // Trigger an update to the upgrade button text
      }
    },
    isCurrentPlan(planName) {
      return this.subscription && this.subscription.currentPlanName === planName;
    }
  },
  mounted() {
    this.fetchSubscriptionStatus(); // Fetch the subscription status when the component is mounted
  },
};
</script>

<style scoped>
.block {
  border-bottom: none;
  display: flex;
  justify-content: center;
}

.note {
  font-size: 12px;
  color: #888;
  display: block;
  margin-top: 5px;
}
</style>
