<template>
  <div v-if="isLoading" class="page-layout">
    <PageLoader />
  </div>
  <router-view v-else />
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
import { useRouter } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";

import { useWorkSpaceStore } from "@/stores/workspace-store";
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

import { getUser, getUserWorkspaces } from "@/services/workspace.service";

export default {
  components: {
    PageLoader,
  },
  setup() {
    const { isLoading } = useAuth0();

    return {
      isLoading,
    };
  },
  computed: {
    ...mapStores(useAppStore),
    ...mapStores(useWorkSpaceStore),
  },
  data() {
    return {
      greeting: "Hello from parent",
      testLoading: true,
    };
  },
  async mounted() {
    console.log("AppVuew Mounted");
    const user = await getUser();
    console.log(user);
    this.appStore.user = user.data;
    console.log("Pushing data into Clarity:");
    console.log(user.data.email)
    await window.clarity("identify", user.data.email, "custom-session-1234", "custom-page-123", user.data.email)


    // Get the first workspace UUID
    const workspaces = await getUserWorkspaces();
    const firstWorkspace = workspaces.data[0];
    const firstWorkspaceUUID = firstWorkspace.workspaceUUID;
    this.workspaceStore.activeWorkspaceUUID = firstWorkspaceUUID;

    console.log("User inforation sending to Intercom");
    console.log(user.data);
    //Intercom setup
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "bkyv518m",
      user_id: user.data.userUUID, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: user.data.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: user.data.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email address
    });

    // Call the fetchSubscriptionStatus action
    await this.workspaceStore.fetchSubscriptionStatus();
  },
};
</script>
