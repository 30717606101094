<template>
  <div class="popup-holder popup-export" :style="{ display: showExportPopup ? 'block' : 'none' }">
    <div class="popup-wrap export-popup">
      <div class="popup-head">
        <h2>Export Transcript</h2>
        <a @click="closeExportPopup" href="#" class="popup-close icon-close"></a>
      </div>
      <div class="popup-body">
        <div class="export-options">
          <h3>Select a format to export:</h3>
          <div class="btn-holder">
            <div class="btn-wrapper">
              <button :disabled="loading && currentFormat === 'txt'" @click="downloadFile('txt')"
                class="btn btn-primary">
                <span v-if="loading && currentFormat === 'txt'" class="spinner"></span>
                <span v-else>Download as TXT</span>
              </button>
            </div>
            <div class="btn-wrapper">
              <button :disabled="loading && currentFormat === 'vtt'" @click="downloadFile('vtt')"
                class="btn btn-primary">
                <span v-if="loading && currentFormat === 'vtt'" class="spinner"></span>
                <span v-else>Download as VTT</span>
              </button>
            </div>
            <div class="btn-wrapper">
              <button :disabled="loading && currentFormat === 'srt'" @click="downloadFile('srt')"
                class="btn btn-primary">
                <span v-if="loading && currentFormat === 'srt'" class="spinner"></span>
                <span v-else>Download as SRT</span>
              </button>
            </div>
            <div class="btn-wrapper">
              <button :disabled="loading && currentFormat === 'media'" @click="downloadMedia" class="btn btn-primary">
                <span v-if="loading && currentFormat === 'media'" class="spinner"></span>
                <span v-else>Download Media File</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadTranscriptByFormat, downloadAudioFile } from "@/services/workspace.service";
import { useAppStore } from "@/stores/app-store";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { mapStores } from "pinia";
import { downloadFile } from "@/utils/downloadHelpers";

export default {
  name: "ExportPopup",
  props: ["showExportPopup"],
  computed: {
    ...mapStores(useAppStore),
    ...mapStores(useWorkSpaceStore),
    // audioFile() {
    //   return this.workspaceStore.activeAudioFile;
    // },
  },
  data() {
    return {
      audioFile: null,
      loading: false, // Loading state to manage button behavior
      currentFormat: null, // Track the current format being downloaded
      audioFileURL: "", // URL for the media file
    };
  },
  methods: {
    closeExportPopup() {
      this.appStore.showExportPopup = false; // Close the popup
    },
    async downloadFile(format) {
      this.audioFile = this.workspaceStore.activeAudioFile;
      this.loading = true;
      this.currentFormat = format;

      try {
        const response = await downloadTranscriptByFormat(this.workspaceStore.activeWorkspaceUUID, this.$route.params.audioFileUUID, format);
        const fileName = this.audioFile.userDefinedName.split('.')[0];
        const fileExtension = format;
        downloadFile(response.data, `${fileName}`, format);
        this.$toast.success(`File downloaded as ${format.toUpperCase()}.`);
      } catch (error) {
        console.error("Failed to download file:", error);
        this.$toast.error("Failed to download file.");
      } finally {
        this.loading = false;
        this.currentFormat = null;
      }
    },
    async downloadMedia() {
      this.loading = true;
      this.audioFile = this.workspaceStore.activeAudioFile;
      this.currentFormat = 'media';
      try {
        const downloadAudioFileResponse = await downloadAudioFile(this.workspaceStore.activeWorkspaceUUID, this.$route.params.audioFileUUID);
        this.audioFileURL = downloadAudioFileResponse.data;

        // Trigger the download using a hidden link
        const downloadLink = document.createElement('a');
        downloadLink.target = '_blank';
        downloadLink.href = this.audioFileURL;
        downloadLink.download = this.audioFile.userDefinedName || 'audio-file';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        this.$toast.success('Media file downloaded successfully.');
      } catch (error) {
        console.error("Failed to download media file:", error);
        this.$toast.error("Failed to download media file.");
      } finally {
        this.loading = false;
        this.currentFormat = null;
      }
    },
  },
};
</script>


<style scoped>
.export-popup {
  max-width: 500px;
  margin: 0 auto;
}

.export-options {
  text-align: center;
}

.btn-holder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 20px;
}

.btn-wrapper {
  width: 100%;
}

.btn-holder .btn {
  width: 100%;
  padding: 10px;
  text-align: center;
  position: relative;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  .btn-holder {
    grid-template-columns: 1fr;
  }
}
</style>
