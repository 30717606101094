<template>
  <PageLayout>
    <div class="main-content_wrap">
      <div class="main-content">
        <div class="container">
          <div class="starter-card_holder">
            <div class="starter-card">
              <h2>Getting Started</h2>
              <p>
                Learn the basics of using Audioscribe.io in just a few minutes!
              </p>
              <div data-supademo-demo="clzosqmrq098tgumtmz4zlsco" class="starter-link">
                <span class="starter-link_txt">Watch a short demo video of how to use AudioScribe.io</span>
                <a class="btn">Watch</a>
                <!-- <p data-supademo-demo="YOUR_DEMO_ID">This is a tooltip trigger</p>
                <button data-supademo-demo="clzosqmrq098tgumtmz4zlsco">View Demo</button> -->
              </div>
              <div @click="toggleImportPopup" class="starter-link">
                <span class="starter-link_txt">Import Your First File</span>
                <a class="btn">Import</a>
              </div>
              <!-- <div class="starter-link">
                <span class="starter-link_txt"
                  >Connect to the meeting platform providers like Zoom or Google Meets</span
                >
                <a href="#" class="btn">Start</a>
              </div> -->
            </div>
          </div>
          <!-- <div class="starter-card_holder">
            <div class="starter_date h3">Yesterday, June 12</div>
            <div class="starter-card secondary">
              <p>
                Lorem ipsum dolor sit amet consectetur. Est aliquet sagittis
                vestibulum convallis tortor nulla. Consequat eleifend ut
                elementum orci. Molestie elementum sagittis eget sagittis cras
                risus in purus. Massa non quis et massa eget.
              </p>
              <div class="starter-link">
                <span class="starter-link_txt"
                  >Lorem ipsum dolor sit amet consectetur. Hendrerit faucibus
                  gravida</span
                >
                <a href="#" class="btn btn-secondary">Start</a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- <aside class="aside">
        <div class="calendar_holder">
          <div class="calendar_top">
            <div class="date-col">
              <div class="date">October 2020</div>
              <div class="day">Today</div>
            </div>
            <div class="nav-col">
              <div class="cal_nav">
                <div class="cal_nav-col">
                  <a href="#" class="nav_icon nav_left icon-arrow-up"></a>
                </div>
                <div class="cal_nav-col">
                  <a href="#" class="nav_icon nav_right icon-arrow-up"></a>
                </div>
              </div>
              <a href="#" class="nav_icon nav_refresh icon-refresh"></a>
            </div>
          </div>
          <div class="calendar_main">
            <div class="calendar">
              <div class="calendar_head">
                <ul class="weekdays">
                  <li>Mo</li>
                  <li>Tu</li>
                  <li>We</li>
                  <li>Th</li>
                  <li>Fr</li>
                  <li>Sa</li>
                  <li>Su</li>
                </ul>
              </div>
              <div class="calendar_body">
                <ul class="days-row">
                  <li>29</li>
                  <li>30</li>
                  <li>31</li>
                  <li><a href="#">1</a></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#">4</a></li>
                </ul>
                <ul class="days-row">
                  <li><a href="#">5</a></li>
                  <li><a href="#">6</a></li>
                  <li><a href="#">7</a></li>
                  <li class="active"><a href="#">8</a></li>
                  <li><a href="#">9</a></li>
                  <li><a href="#">10</a></li>
                  <li><a href="#">11</a></li>
                </ul>
                <ul class="days-row">
                  <li><a href="#">12</a></li>
                  <li><a href="#">13</a></li>
                  <li><a href="#">14</a></li>
                  <li><a href="#">15</a></li>
                  <li><a href="#">16</a></li>
                  <li><a href="#">17</a></li>
                  <li><a href="#">18</a></li>
                </ul>
                <ul class="days-row">
                  <li><a href="#">19</a></li>
                  <li><a href="#">20</a></li>
                  <li><a href="#">21</a></li>
                  <li><a href="#">22</a></li>
                  <li><a href="#">23</a></li>
                  <li><a href="#">24</a></li>
                  <li><a href="#">25</a></li>
                </ul>
                <ul class="days-row">
                  <li><a href="#">26</a></li>
                  <li><a href="#">27</a></li>
                  <li><a href="#">28</a></li>
                  <li><a href="#">29</a></li>
                  <li><a href="#">30</a></li>
                  <li><a href="#">31</a></li>
                  <li>1</li>
                </ul>
              </div>
            </div>
          </div>
          <ul class="event-list">
            <li class="active">
              <div class="event-date">Today 8th October</div>
              <ul class="event-items">
                <li class="no-events"><i class="icon-calendar-alt"></i>No events today.</li>
              </ul>
            </li>
            <li>
              <div class="event-date">Friday - October 9</div>
              <ul class="event-items">
                <li>
                  <a href="#">
                    <div class="event-time">5:50-10:30PM</div>
                    <div class="event-name">Flight to San Salvador</div>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <div class="event-date">Saturday - October 10</div>
              <ul class="event-items">
                <li>
                  <a href="#">
                    <div class="event-time">5:50-10:30PM</div>
                    <div class="event-name">Meeting with Jane</div>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="today-events">
            <li>
              <div class="today-event_time">8 AM</div>
              <div class="today-event_name">Breakfast</div>
            </li>
            <li>
              <div class="today-event_time">10 AM</div>
              <div class="today-event_name">Travel to venue</div>
            </li>
            <li>
              <div class="today-event_time">10:30 AM</div>
              <div class="today-event_name">Ceremony</div>
            </li>
          </ul>
          <div class="btn-holder">
            <a href="#" class="btn btn-secondary-outline w-100">
              <span class="btn-txt"
                ><span class="btn-icon"
                  ><img
                    src="@/assets/images/google-calendar.svg"
                    width="20"
                    height="2"
                    alt="Image Description" /></span
                >Open Gmail Calendar</span
              >
            </a>
            <a href="#" class="btn btn-secondary-outline">
              <span class="btn-txt"><span class="btn-icon icon-voice-search"></span>Record</span>
            </a>
          </div>
        </div>
      </aside> -->
    </div>
    <div class="popup-container">
      <div class="popup-holder popup-workspace" style="display: none">
        <div class="popup-wrap">
          <div class="popup-head">
            <h2>Add New Worskpace</h2>
            <a href="#" class="popup-close icon-close"></a>
          </div>
          <div class="popup-body">
            <form action="#" class="login-form error-active">
              <div class="form-input">
                <input type="text" id="workspace" name="workspace" placeholder="Workspace Name" />
                <div class="form-error">
                  <span class="error-icon icon-exclamation-mark"></span>
                  <span class="error-txt">Workspace name invalid. Please remove the special
                    characters</span>
                </div>
              </div>
              <div class="btn-holder">
                <input type="submit" class="btn" value="Create Workspace" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<script>
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

import PageLayout from "@/components/PageLayout.vue";
export default {
  name: "HomeView",
  components: {
    PageLayout,
  },
  computed: {
    ...mapStores(useAppStore),
  },
  data() {
    return {
      greeting: "Hello from parent",
    };
  },
  created() {
    // Add the 'login-page' class to the body and remove the 'nav-active' class
    document.body.classList.add('nav-active');
    document.body.classList.remove('login-page');
  },
  mounted() {
    Supademo("023c46ee02cfaa46fa28f2055039a95c8c15f74aac4d35a9f78af6467c8f63ae", {
      variables: {
        email: "", // optional user email
        name: ""   // optional user name
        // add your custom variables here
      }
    });
  },
  methods: {
    toggleImportPopup: function () {
      console.log("toggleImportPopup");
      this.appStore.showImportPopup = !this.appStore.showImportPopup;
    },
    toggleDemoPopup: function () {
      console.log("toggleDemoPopup");
      this.appStore.showDemoPopup = !this.appStore.showDemoPopup;
    },
  },
};
</script>
