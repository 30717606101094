<template>
  <div class="nav-footer">
    <div v-if="!isSubscribed" class="upgrade-box">
      <span class="plan-label">Free Plan</span>
      <p class="upgrade-message">
        <strong>{{ remainingFiles }}</strong> free {{ remainingFiles === 1 ? 'file' : 'files' }} remaining today. <br />
        Upgrade to a paid plan to upload more.
      </p>
      <router-link to="/settings/plans" class="btn">Upgrade now</router-link>
    </div>
    <ul class="user-list">
      <li title="Settings">
        <router-link to="/settings" class="icon-setting"></router-link>
      </li>
      <logoutButton></logoutButton>
    </ul>
    <div class="centered-content">
      <span v-if="appStore?.user?.email" class="footer-email">{{ appStore?.user?.email }}</span>
    </div>
  </div>
</template>

<script>
import { isSubscribed, hasUserExceededFileUploadLimit } from "@/services/workspace.service";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import logoutButton from "./LogoutButton.vue";
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

export default {
  name: "NavFooter",
  components: {
    logoutButton,
  },
  computed: {
    ...mapStores(useAppStore),
    ...mapStores(useWorkSpaceStore),
  },
  data() {
    return {
      isSubscribed: true, // Default to subscribed
      hasExceededLimit: false, // Default to not exceeded limit
      fileCount: 0,
      remainingFiles: 0,
      intervalId: null,
    };
  },
  mounted() {
    this.checkSubscription();
  },
  methods: {
    async checkSubscription() {
      try {
        //const response = await isSubscribed();
        //console.log("Subscription status", response.data.isSubscribed);
        this.isSubscribed = this.workspaceStore.isSubscribedState;

        // Start checking the file upload limit every 20 seconds if not subscribed
        if (!this.isSubscribed) {
          this.checkFileUploadLimit(); // Initial check
          this.intervalId = setInterval(this.checkFileUploadLimit, 20000);
        }
      } catch (error) {
        console.error("Failed to check subscription status", error);
      }
    },
    async checkFileUploadLimit() {
      try {
        const response = await hasUserExceededFileUploadLimit(this.workspaceStore.activeWorkspaceUUID);
        console.log("File upload limit", response.data.hasExceededLimit);
        this.hasExceededLimit = response.data.hasExceededLimit;
        this.fileCount = response.data.count;

        // Ensure remainingFiles is not below 0
        this.remainingFiles = Math.max(0, response.data.remainingFiles);
      } catch (error) {
        console.error("Failed to check file upload limit", error);
      }
    },
  },
  beforeUnmount() {
    // Clear the interval if it was set
    if (this.intervalId) {
      console.log("Clearing interval before unmounting");
      clearInterval(this.intervalId);
    }
  },
  beforeDestroy() {
    // Clear the interval if it was set
    if (this.intervalId) {
      console.log("Clearing interval");
      clearInterval(this.intervalId);
    }
  },
  watch: {
    "workspaceStore.checkUserSubscription": {
      immediate: true,
      handler(newVal) {
        if (newVal == true) {
          this.checkSubscription();
        }
      },
    },
  }
}
</script>

<style scoped>
.nav-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.upgrade-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  max-width: 300px;
}

.plan-label {
  font-weight: 600;
  color: #0d2a3a;
  margin-bottom: 8px;
}

.upgrade-message {
  font-size: 14px;
  margin: 0 0 16px 0;
}

.upgrade-button {
  background-color: #f16622;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.upgrade-button:hover {
  background-color: #3700b3;
}

.user-list {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.footer-email {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .footer-email {
    font-size: 10px;
  }
}

@media (min-width: 1366px) {
  .footer-email {
    font-size: 12px;
  }
}
</style>